<template>
  <v-autocomplete
    ref="input"
    v-model="valueMutation"
    v-bind="$attrs"
    :search-input.sync="search"
    outlined
    clearable
    chips
    auto-select-first
    :items="selectList"
    item-value="id"
    item-text="fullText"
    item-disabled="deleted_at"
    :loading="loadingAction"
    :return-object="returnObject"
    :placeholder="$attrs.placeholder ? $attrs.placeholder : $t('label.select_account')"
    v-on="$listeners"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left>
          <v-img :src="data.item.avatar" />
        </v-avatar>
        {{ data.item.client_name_display }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="(typeof data.item !== 'object')">
        <v-list-item-content v-text="data.item" />
      </template>
      <template v-else>
        <v-list-item-avatar>
          <img :src="data.item.avatar">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.client_name_display" />
          <v-list-item-subtitle v-html="data.item.phone" />
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text
            v-if="data.item.deleted_at"
            class="error--text"
            v-text="'удален'"
          />
        </v-list-item-action>
      </template>
    </template>
    <template
      v-if="barcodeReader"
      v-slot:append-outer
    >
      <!-- <v-btn @click="showBarcodeReader = true" icon>
        <v-icon>
          $iconify_ion-barcode-outline
        </v-icon>
      </v-btn> -->
      <v-icon
        style="margin-top: -6px;"
        @click="openBarcodeReader"
      >
        $iconify_ion-barcode-outline
      </v-icon>
      <v-dialog
        v-model="showBarcodeReader"
      >
        <v-card>
          <v-card-text v-if="showBarcodeReader">
            <v-container>
              <v-row>
                <v-col>
                  <v-skeleton-loader
                    v-if="streamBarcodeReaderLoading"
                    type="image"
                  />
                  <stream-barcode-reader
                    @decode="onDecode"
                    @loaded="onLoaded"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-h6  font-weight-bold">
                  {{ barcode }}
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <template
      v-if="$slots['no-data']"
      v-slot:no-data
    >
      <slot name="no-data" />
    </template>
    <template
      v-if="$slots['append-item']"
      v-slot:append-item
    >
      <slot name="append-item" />
    </template>
  </v-autocomplete>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { StreamBarcodeReader } from 'vue-barcode-reader'

  export default {
    name: 'AccountSelect',
    components: { StreamBarcodeReader },
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      value: { type: [Number, Object, Array], default: undefined },
      programId: { type: [Number], default: undefined },
      withDeleted: { type: Boolean, default: true },
      barcodeReader: { type: Boolean, default: false },
      returnObject: { type: Boolean, default: false },
    },
    data () {
      return {
        loadingAction: false,
        showBarcodeReader: false,
        streamBarcodeReaderLoading: true,
        barcode: null,
        search: '',
      }
    },
    computed: {
      ...mapGetters({
        globalProgramId: 'programId',
        accountShortList: 'account/account/accountShortList',
      }),
      accountProgramId () {
        return this.programId || this.globalProgramId
      },
      selectList () {
        return this.accountShortList.filter(item => this.withDeleted || item.deleted_at === null).map((item) => {
          const fullText = [item.id, item.fio, item.client_name, item.phone, item.email, item.barcode].join(' ')

          return Object.assign({}, item, {
            phone: '+' + item.phone,
            client_name_display: item.client_name || this.$t('no_name'),
            fullText,
          })
        })
      },
      valueMutation: {
        get: function () {
          return this.value
        },
        set: function (v) {
          this.$emit('update', v)
        },
      },
    },
    watch: {
      accountProgramId (v) {
        this.init()
      },
      showBarcodeReader (v) {
        if (v === false) {
          this.onBarcodeReaderClose()
        } else {
          this.streamBarcodeReaderLoading = true
        }
      },
    },
    created () {
      this.init()
    },

    methods: {
      ...mapActions({
        getAccountShortList: 'account/account/getAccountShortList',
      }),
      focus () {
        this.$refs.input.focus()
      },
      onBarcodeReaderClose () {
        console.log('onBarcodeReaderClose', this.barcode)
        // this.barcode = '1002030000100797' // dev-test
        if (this.barcode) {
          // find by barcode
          const account = this.selectList.find(item => String(item.barcode) === String(this.barcode))
          if (account) {
            if (this.returnObject) {
              this.valueMutation = account
            } else {
              this.valueMutation = account.id
            }
          } else {
            this.$refs.input.focus()
            this.$nextTick(() => {
              this.search = this.barcode
              this.$refs.input.activateMenu()
            })
          }
        }
      },
      openBarcodeReader () {
        this.barcode = null
        // this.onBarcodeReaderDialog(false)
        this.showBarcodeReader = true
      },
      onDecode (value) {
        console.log('StreamBarcodeReader.onDecode', value)
        if (!this.showBarcodeReader) return
        this.barcode = value
        setTimeout(() => {
          this.showBarcodeReader = false
        }, 1500)
      },
      onLoaded () {
        console.log('StreamBarcodeReader.onLoaded')

        this.streamBarcodeReaderLoading = false
      },
      remove (item) {
        if (Array.isArray(this.valueMutation)) {
          this.valueMutation = this.valueMutation.filter(v => v !== item.id)
        } else {
          this.valueMutation = null
        }
        // const index = this.valueMutation.indexOf(item.id)
        // if (index >= 0) this.valueMutation.splice(index, 1)
      },
      async init () {
        try {
          this.loadingAction = true
          await this.getAccountShortList({ programId: this.accountProgramId, withTrashed: true })
        } catch (error) {
          console.error(error)
        } finally {
          this.loadingAction = false
        }
      },

    },
  }
</script>
